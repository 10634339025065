import { css } from '@emotion/react';

export const headingStyle = css`
    font-family: 'Bebas Neue', 'Impact', sans-serif;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
`;

const style2 = css`
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
`;

export const h1 = css`
    ${headingStyle};
    font-size: 4.5rem;

    @media (min-width: 1280px) {
        font-size: 6rem;
        line-height: 100%;
    }
`;

export const h2 = css`
    ${headingStyle}
    font-size: 3rem;

    @media (min-width: 1280px) {
        font-size: 4.5rem;
    }
`;

export const h3 = css`
    ${style2};
    font-size: 1.75rem;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h4 = css`
    ${style2};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 1.75rem;
    }
`;

export const smTxt = css`
    font-size: 1rem;

    @media (min-width: 1280px) {
        font-size: 1.125rem;
    }
`;

export const lgTxt = css`
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;
