import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;

    &[aria-current='page'] {
        box-shadow: inset 0 -2px 0 ${theme.colors.red};
    }

    &.current-nav-category {
        box-shadow: inset 0 -1px 0 rgba(213, 12, 57, 0.8);

        > svg path {
            fill: ${theme.colors.red};
        }
    }

    &[data-mobile-expanded],
    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    @media (pointer: fine) {
        :hover {
            background-color: rgba(0, 0, 0, 0.05);
            color: ${theme.colors.red};
        }

        :focus-visible {
            background-color: rgba(0, 0, 0, 0.05);
            color: ${theme.colors.red};
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    text-transform: capitalize;
    color: ${theme.colors.white};
    margin-bottom: 40px;
    letter-spacing: 0.005em;
    font-weight: 600;
    line-height: 150%;
    font-size: 1rem;
    padding: 8px 12px;

    @media (min-height: 700px) {
        margin-bottom: 72px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0 !important;
        margin-right: 32px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;
        transform: rotate(-90deg);

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.white};
        }
    }

    @media (min-width: 1024px) {
        > svg {
            transform: rotate(0);
        }
    }
`;
