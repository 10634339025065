import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn, SecondaryBtn } from '@/buttons';
import { ReactComponent as CloseIcon } from '@a/icons/left-arrow.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import useWindowSize from '@s/hooks/useWindowSize';
import { navigate } from 'gatsby';
import { Fragment, useState } from 'react';
import { MobSubNav } from './MobSubNav';
import { scrollToForm } from './Nav';
import { navLinkStyles } from './NavLinkStyles';
import { navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${px};
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: ${theme.colors.dark};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
        ${flexSpace};
        margin-bottom: 40px;
    }

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }

    @media (min-height: 700px) {
        > div {
            margin-bottom: 80px;
        }
    }
`;

const NavMenu = styled.nav<{ subDialogOpen: boolean }>`
    ${flexCenter};
    flex-direction: column;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    height: calc(100% - 100px);
    overflow-y: auto;

    @media (min-height: 700px) {
        height: calc(100% - 150px);
    }

    ${({ subDialogOpen }) =>
        subDialogOpen &&
        css`
            visibility: ${subDialogOpen ? 'hidden' : 'visible'};
            pointer-events: ${subDialogOpen ? 'none' : 'auto'};
            opacity: ${subDialogOpen ? 0 : 1};
        `};
`;

export const MobNav = () => {
    const [open, setOpen] = useState(false);
    const [subDialogOpen, setSubDialogOpen] = useState(false);

    const { width } = useWindowSize();

    useRouteChange(setOpen);

    const { title } = useLocationData();

    const scrollToContactForm = () => {
        setOpen(false);
        scrollToForm();
    };

    return width < 1024 ? (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogOpen>
                <PlainBtn
                    aria-label={'open navigation menu'}
                    css={css`
                        @media (min-width: 1024px) {
                            display: none;
                        }
                    `}
                >
                    <MenuIcon />
                </PlainBtn>
            </DialogOpen>

            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="website main navigation menu"
            >
                <div>
                    <Logo aria-label="FT Mayers Testosterone" />

                    <DialogClose>
                        <PlainBtn data-mobile-expanded="true">
                            <CloseIcon />
                        </PlainBtn>
                    </DialogClose>
                </div>
                <NavMenu subDialogOpen={subDialogOpen}>
                    {navLinks.slice(0, -1).map((data, i) =>
                        data.links ? (
                            <MobSubNav
                                key={i}
                                text={data.text}
                                links={data.links}
                                setSubDialogOpen={setSubDialogOpen}
                            />
                        ) : (
                            <CLink to={data.link} css={navLinkStyles} key={i}>
                                {data.text}
                            </CLink>
                        )
                    )}
                    <SecondaryBtn
                        onClick={() =>
                            title === 'contact us'
                                ? navigate('/', {
                                      state: {
                                          scrollToForm: true,
                                      },
                                  })
                                : scrollToContactForm()
                        }
                    >
                        {navLinks[navLinks.length - 1].text}
                    </SecondaryBtn>
                </NavMenu>
            </DialogContent>
        </Dialog>
    ) : (
        <Fragment />
    );
};
