import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)`
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 10px;
    box-shadow: 0 0 0 0 transparent, inset 0 0 0 2px ${({ theme }) => theme.colors.red};
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 8px 24px -12px rgba(213, 12, 57, 0.62),
            inset 0 0 0 1px ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.dark};
    }

    :focus-visible {
        box-shadow: 0px 8px 24px -12px rgba(213, 12, 57, 0.62),
            inset 0 0 0 1px ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.dark};
    }

    :active {
        box-shadow: 0px 16px 24px -12px rgba(213, 12, 57, 0.62),
            inset 0 0 0 2px ${({ theme }) => theme.colors.red};
        background-color: ${({ theme }) => theme.colors.dark};
    }
`;
