import { Fragment } from 'react';

export const siteInfo = {
    email: {
        link: 'mailto:info@fortmyerstestosterone.com',
        text: 'info@fortmyerstestosterone.com',
    },
    address: {
        link: 'https://www.google.com/maps/search/4600+Summerlin+Road+STE+c',
        text: (
            <Fragment>
                4600 Summerlin Road STE c-2 #514
                <br />
                Fort Myers , 33919
            </Fragment>
        ),
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'testosterone',
        link: '/testosterone/',
    },
    {
        text: 'other services',
        link: '/services/',
    },
    {
        text: 'our process',
        link: '/our-process/',
    },
    {
        text: 'blog',
        link: '/blog/',
    },
    {
        text: 'contact us now',
        link: '#contact',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: '',
        link: '',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
