import { CLink } from '@/CLink';
import styled from '@emotion/styled';
import { LogoLink } from './Nav';
import { navLinks } from './NavLinks';
import { flexStart, linkStyles, px, py } from './styles/classes';

const Section = styled.footer`
    ${py};
    ${px};
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    max-width: 1164px;
    margin: 0 auto;

    > a:first-of-type {
        margin-bottom: 48px;
    }

    > p {
        font-size: 0.75rem;
        margin-top: 64px;
    }
`;

const Links = styled.div`
    ${flexStart};
    flex-direction: column;

    > p {
        font-weight: 600;
        margin: 0 0 28px;
    }

    > a {
        ${linkStyles};
        color: rgba(255, 255, 255, 0.7);
        text-transform: capitalize;

        :not(:last-of-type) {
            margin-bottom: 36px;
        }
    }
`;

export const Footer = () => {
    return (
        <Section>
            <LogoLink />
            <Links>
                <p>Discover</p>
                {navLinks.map((data, i) => (
                    <CLink key={i} to={data.link}>
                        {data.text}
                    </CLink>
                ))}

                <CLink to="/privacy-policy/">privacy policy</CLink>
            </Links>
            <p>Fort Myers Testosterone LLC © {new Date().getFullYear()}. All rights reserved.</p>
        </Section>
    );
};
